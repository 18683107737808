import { MarginProps, MinHeightProps, PaddingProps } from 'styled-system';

import { CmsApiCtaHeroBlockLayout } from 'modules/cms/api/types/blocks/CmsApiCtaHeroBlock';

type CmsCtaHeroBlockPropsByLayout = {
  imageHeight: number;
  minHeight: MinHeightProps['minHeight'];
  py: PaddingProps['py'];
  mb: MarginProps['mb'];
  mt: MarginProps['mt'];
};

export const cmsCtaHeroBlockPropsByLayout: Record<
  CmsApiCtaHeroBlockLayout,
  CmsCtaHeroBlockPropsByLayout
> = {
  [CmsApiCtaHeroBlockLayout.STANDARD]: {
    imageHeight: 1024,
    minHeight: [null, null, null, 440],
    py: 96,
    mb: undefined,
    mt: undefined,
  },
  [CmsApiCtaHeroBlockLayout.COMPACT]: {
    imageHeight: 200,
    minHeight: [null, null, null, 170],
    py: 32,
    mb: 32,
    mt: 32,
  },
};
