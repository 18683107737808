import { Box } from 'components/Box/Box';
import { Hero } from 'components/Hero/Hero';
import { LegacyPrimaryButton } from 'components/LegacyButton/LegacyPrimaryButton';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { RichText } from 'components/Text/RichText';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import {
  CmsApiCtaHeroBlockData,
  CmsApiCtaHeroBlockTextColor,
} from 'modules/cms/api/types/blocks/CmsApiCtaHeroBlock';
import { colors } from 'theme/theme';
import { trackAnchorClicked } from 'utils/analytics/track/trackAnchorClicked';

import { cmsCtaHeroBlockPropsByLayout } from './cmsCtaHeroBlockPropsByLayout';

type Props = {
  data: CmsApiCtaHeroBlockData;
  isPageHeader?: boolean;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsCtaHeroBlock({
  data: {
    buttonText,
    buttonUrl,
    text,
    bodyText,
    textColor,
    image,
    withOverlay,
    layout,
  },
  isPageHeader,
  blockIndex,
  pageUpdated,
}: Props) {
  const { imageHeight, minHeight, py, mb, mt } =
    cmsCtaHeroBlockPropsByLayout[layout];

  const color =
    textColor === CmsApiCtaHeroBlockTextColor.DARK_CONTENT_GREY
      ? colors.darkContentGrey
      : colors.white;

  return (
    <Hero
      withOverlay={withOverlay}
      heroImage={image}
      imageHeight={imageHeight}
      eagerLoadImage={blockIndex <= 1}
      data-qa-id="call-to-action-hero"
      minHeight={minHeight}
      py={py}
      mb={mb}
      mt={mt}
    >
      <Box maxWidth="768px" m="0 auto" px="20px">
        <RichTextHeadline
          color={color}
          textAlignCenter
          tag={isPageHeader ? 'h1' : undefined}
          styleAs={isPageHeader ? 'h2' : undefined}
          $reverseUnderline
          $noMarginBottom={!(buttonUrl && buttonText)}
        >
          {text}
        </RichTextHeadline>
        {bodyText && (
          <Box mt="36px" mb="40px" textAlign="center">
            <RichText styleAs="body" color={color} html={bodyText} />
          </Box>
        )}
      </Box>

      {buttonUrl && buttonText && (
        <Box display="flex" mt={bodyText ? '0px' : '4px'}>
          <LegacyPrimaryButton
            component={StyledNativeLinkWithRef}
            href={buttonUrl}
            onClick={(event) =>
              trackAnchorClicked(event, 'CMS Block', {
                block_position: blockIndex,
                block_type: 'Call to Action Hero',
                cta_type: 'Button',
                button_text: buttonText,
                page_updated: pageUpdated,
              })
            }
          >
            {buttonText}
          </LegacyPrimaryButton>
        </Box>
      )}
    </Hero>
  );
}
